import { accordion } from "./accordion";
import { toggleHamburger } from "./toggleHamburger";
import { initializeViewport } from "./initializeViewport";
toggleHamburger("js-hamburger", "js-spmenu", 1024);
initializeViewport();
accordion();

document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopButton = document.querySelector(".js-button-pagetop");
  if (scrollToTopButton) {
    scrollToTopButton.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const newsItems = document.querySelectorAll(".p-top-news__item");
  const thumbnailContainer = document.querySelector(".p-top-news__thumbnail");

  // 初期状態として、data-selected-newsに対応するアイテムに_hoverクラスを追加
  function updateHoverClass() {
    const selectedIndex = thumbnailContainer
      ? thumbnailContainer.getAttribute("data-selected-news")
      : null;

    if (selectedIndex === null) return;

    newsItems.forEach((item, index) => {
      if (index == selectedIndex) {
        item.classList.add("_active");
      } else {
        item.classList.remove("_active");
      }
    });
  }

  newsItems.forEach((item, index) => {
    item.addEventListener("mouseover", function () {
      thumbnailContainer.setAttribute("data-selected-news", index);
      updateHoverClass();
    });

    item.addEventListener("focus", function () {
      thumbnailContainer.setAttribute("data-selected-news", index);
      updateHoverClass();
    });
  });

  // 初期状態の_hoverクラスの適用
  updateHoverClass();
});

document.addEventListener("DOMContentLoaded", function () {
  // アニメーション
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("_active");
        }
      });
    },
    {
      root: null,
      rootMargin: "0px 0px -20% 0px",
      threshold: 0,
    }
  );

  const elements = document.querySelectorAll(".js-animation");
  elements.forEach((element) => {
    observer.observe(element);

    if (
      element.getBoundingClientRect().top <= window.innerHeight &&
      element.getBoundingClientRect().bottom >= 0
    ) {
      element.classList.add("_active");
    }
  });
  // スムーススクロール
  var scrollLinks = document.querySelectorAll('a[href^="#"]');
  scrollLinks.forEach(function (link) {
    link.addEventListener("click", function (event) {
      event.preventDefault();

      var targetId = this.getAttribute("href").substring(1);
      var targetElement = document.getElementById(targetId);
      if (targetElement) {
        var offsetTop =
          targetElement.getBoundingClientRect().top + window.pageYOffset - 85;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    });
  });
});

gsap.registerPlugin(ScrollTrigger);

const services = document.querySelectorAll(".p-top-service__item");
const serviceHeading = document.querySelector(".p-top-service__heading");

let scrollTriggerInstance;

function initServices() {
  const serviceAnimation = gsap.timeline();
  let serviceHeight;

  // function setupServices() {
  //   serviceAnimation.clear();
  //   serviceHeight = services[0].offsetHeight;
  //   services.forEach((service, index) => {
  //     if (index > 0) {
  //       gsap.set(service, { y: index * serviceHeight});
  //       serviceAnimation.to(
  //         service,
  //         { y: 0, duration: index * 0.5, ease: "none" },
  //         0
  //       );
  //     }
  //   });
  // }

  function setupServices() {
    serviceAnimation.clear();
    serviceHeight = services[0].offsetHeight;

    services.forEach((service, index) => {
      if (index > 0) {
        // 初期位置を設定
        gsap.set(service, { y: index * serviceHeight });

        // アニメーションの最終位置を設定
        let finalY = 0;
        if (index === 1) {
          finalY = 30; // 2番目のアイテムは30px上に余白を持たせる
        } else if (index === 2) {
          finalY = 60; // 3番目のアイテムは60px上に余白を持たせる
        }

        serviceAnimation.to(
          service,
          { y: finalY, duration: index * 0.5, ease: "none" },
          0
        );
      }
    });
  }

  function applyScrollTrigger() {
    setupServices();
    scrollTriggerInstance = ScrollTrigger.create({
      trigger: ".p-top-service__list",
      start: "top-=118 top",
      pin: true,
      end: () => `+=${services.length * serviceHeight}`,
      scrub: true,
      animation: serviceAnimation,
      invalidateOnRefresh: true,
    });

    ScrollTrigger.addEventListener("refreshInit", setupServices);
  }

  function killScrollTrigger() {
    if (scrollTriggerInstance) {
      scrollTriggerInstance.kill(); // 既存のScrollTriggerを解除
      scrollTriggerInstance = null; // インスタンスをクリア
      ScrollTrigger.removeEventListener("refreshInit", setupServices);
      gsap.set(services, { clearProps: "all" }); // アニメーションの影響をクリア
    }
  }

  function checkWidth() {
    if (window.innerWidth >= 1200) {
      if (!scrollTriggerInstance) {
        applyScrollTrigger(); // 768px以上の場合、ScrollTriggerを適用
      }
    } else {
      killScrollTrigger(); // 768px未満の場合、ScrollTriggerを無効化
    }
  }

  // 初回チェック
  checkWidth();

  // リサイズイベントで再チェック
  window.addEventListener("resize", () => {
    checkWidth();
    ScrollTrigger.refresh();
  });
}

if (services.length > 0 && serviceHeading) {
  initServices();
}

// ローディングアニメーション
class TextWrapper {
  constructor() {}

  wrapCharacterWithSpan(char) {
    return `<span>${char}</span>`;
  }

  // 指定された要素内のテキストを1文字ずつ<span>タグで囲むメソッド
  wrapAllTextWithSpans(selector) {
    const elements = document.querySelectorAll(selector); // 指定された要素を全て取得
    elements.forEach((element) => {
      // 取得した要素全てに対してループ処理
      const texts = element.textContent // 要素内のテキストを取得
        .split("") // 1文字ずつ分割
        .map((char) => this.wrapCharacterWithSpan(char)) // 各文字を<span>タグで囲む
        .join(""); // 文字列に戻す

      element.innerHTML = texts; // 要素内に<span>タグで囲まれたテキストを表示
    });
  }
}

class Main {
  constructor() {
    this._init();
  }
  _init() {
    const textWrapper = new TextWrapper();
    textWrapper.wrapAllTextWithSpans(".js-textSpan");
  }
}
new Main();

// 初回訪問かどうかを判定
if (!sessionStorage.getItem("visited")) {
  // 初回訪問の場合、splashTimelineとmainContentTimelineを実行
  sessionStorage.setItem("visited", "true"); // 訪問フラグを設定

  // 最初のタイムライン
  const splashTimeline = gsap.timeline({});
  splashTimeline
    .fromTo(
      ".s-splash-loading__main.js-textSpan > span",
      0.5,
      {
        opacity: 0,
        scale: 2,
        x: -10,
      },
      {
        opacity: 1,
        scale: 1,
        x: 0,
        ease: "power3.out",
        stagger: {
          amount: 0.3,
        },
      }
    )
    .fromTo(
      ".s-splash-loading__sub.js-textSpan > span",
      0.4,
      {
        opacity: 0,
        scale: 0,
        x: -10,
      },
      {
        opacity: 1,
        scale: 1,
        x: 0,
        ease: "power3.out",
        stagger: {
          amount: 0.3,
        },
      }
    )
    .fromTo(
      ".s-splash-loading__logo",
      0.4,
      {
        x: -30,
        opacity: 0,
      },
      {
        x: 0,
        ease: "power3.out",
        opacity: 1,
        delay: 0.5,
      }
    )
    .fromTo(
      ".s-splash-loading__body",
      0.4,
      {
        x: 0,
        opacity: 1,
      },
      {
        x: 30,
        ease: "power3.out",
        opacity: 0,
        delay: 0.5,
      },
      "-=0.9"
    )
    .fromTo(
      ".s-splash-loading",
      1,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: "power3.out",
        delay: 0.7,
      }
    )
    .add(() => {
      const splashLoading = document.querySelector(".s-splash-loading");
      if (splashLoading) {
        splashLoading.style.display = "none";
      }
    });

  // splashTimelineの後にmainContentTimelineを開始
  splashTimeline.add(() => {
    startMainContentTimeline(0); // 初回訪問時は遅延なしで開始
  });
} else {
  // 2回目以降の訪問の場合、mainContentTimelineのみを遅延付きで実行
  startMainContentTimeline(0.5); // 2回目以降の訪問時は1秒の遅延
}

function startMainContentTimeline(delay) {
  const mainContentTimeline = gsap.timeline({
    delay: delay, // 指定された遅延時間を設定
  });

  mainContentTimeline
    .fromTo(
      ".p-top-mv__parts.js-textSpan > span",
      0.4,
      {
        y: "105%",
      },
      {
        y: 0,
        ease: "power2.out",
        stagger: {
          amount: 0.3,
        },
      }
    )
    .fromTo(
      ".p-top-mv__sub.js-textSpan > span",
      0.4,
      {
        y: "105%",
      },
      {
        y: 0,
        ease: "power2.out",
        stagger: {
          amount: 0.3,
        },
      },
      "-=0.2"
    );
}

gsap.utils.toArray(".js-gsap-fade-in-up").forEach((parentElement) => {
  gsap.fromTo(
    parentElement.querySelectorAll(".js-gsap-fade-in-up__child"), // 子要素を選択
    {
      opacity: 0,
      y: 30,
    },
    {
      opacity: 1,
      y: 0,
      duration: 1.0, // 各アニメーションの時間
      ease: "power3.out",
      stagger: 0.3, // 各要素のアニメーション間の遅延
      scrollTrigger: {
        trigger: parentElement, // トリガーとなる要素
        start: "top 70%", // アニメーションを開始する位置（画面の80%位置で開始）
        end: "bottom 20%", // アニメーションを終了する位置
        toggleActions: "play none none none", // スクロールアクションの設定（再生のみ）
      },
    }
  );
});

gsap.utils.toArray(".js-gsap-swipe-up").forEach((parentElement) => {
  gsap.fromTo(
    parentElement.querySelectorAll(".js-gsap-swipe-up__child"), // 子要素を選択
    {
      y: "100%", // 初期のY位置（下から）
    },
    {
      y: 0, // 終了時のY位置
      duration: 1.0, // 各アニメーションの時間
      ease: "power2.out", // イージング
      stagger: 0.3, // 各要素のアニメーション間の遅延
      scrollTrigger: {
        trigger: parentElement, // 親要素がスクロールトリガーの対象
        start: "top 80%", // スクロール位置の開始点
        end: "top 50%", // スクロール位置の終了点
      },
    }
  );
});

document
  .querySelectorAll(
    ".s-splash-loading__main.js-textSpan > span, .s-splash-loading__sub.js-textSpan > span, .s-splash-loading__logo, .s-splash-loading__body, .s-splash-loading"
  )
  .forEach((el) => {
    el.classList.add("gsap-in-view");
  });

// 数字のアニメーション
function animateValue(obj, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.textContent = Math.floor(progress * (end - start) + start); // カンマを除去するために、toLocaleString()を削除
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

document.addEventListener("DOMContentLoaded", () => {
  const elements = document.querySelectorAll(".js-numbers-animation");

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target;
        const startValue = 0; // 開始値を設定
        const endValue = parseInt(element.dataset.numEnd) || 0; // data-num-end属性から終了値を取得、なければ0
        const duration = 1000; // アニメーションの時間 (ミリ秒)

        animateValue(element, startValue, endValue, duration);
        observer.unobserve(element); // アニメーションが一度実行された後、再度実行しないように監視を停止
      }
    });
  });

  elements.forEach((element) => {
    observer.observe(element);
  });
});

let splitAnimationSections = document.querySelectorAll(
  ".js-splitTextAnimation"
);

splitAnimationSections.forEach((section) => {
  let target = section.querySelector(".js-splitTextAnimation__target");
  if (target && !target.classList.contains("is-active")) {
    let newText = "";
    let spanText = target.innerHTML;
    spanText.split("").forEach((char) => {
      if (char === " ") {
        char = "&nbsp;";
      }
      newText += "<span>" + char + "</span>";
    });
    let newTextBefore =
      "<div class='js-splitTextAnimation__before'>" + newText + "</div>";
    let newTextAfter =
      "<div class='js-splitTextAnimation__after'>" + newText + "</div>";
    newText =
      "<span class='js-splitTextAnimation__text-wrap'>" +
      newTextBefore +
      newTextAfter +
      "</span>";
    target.innerHTML = newText;

    let beforeSpan = target
      .querySelector(".js-splitTextAnimation__before")
      .querySelectorAll("span");
    let afterSpan = target
      .querySelector(".js-splitTextAnimation__after")
      .querySelectorAll("span");

    section.addEventListener("mouseenter", () => {
      gsap.to(beforeSpan, {
        y: "-105%",
        stagger: 0.025,
        ease: "power2.out",
      });
      gsap.to(afterSpan, {
        y: "0%",
        stagger: 0.025,
        ease: "power2.out",
      });
    });

    section.addEventListener("mouseleave", () => {
      gsap.to(beforeSpan, {
        y: "0%",
        stagger: 0.025,
        ease: "power2.out",
      });
      gsap.to(afterSpan, {
        y: "105%",
        stagger: 0.025,
        ease: "power2.out",
      });
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  var recruitPeopleSlider = new Swiper(".js-recruit-people-slider", {
    loop: true,
    speed: 1000,
    slidesPerView: "auto",
    centeredSlides: true,
    autoplay: {
      delay: 3000,
    },
  });
});

const recruitCircle = document.querySelector(".js-gsap-recruit-circle");

if (recruitCircle) {
  gsap.set(recruitCircle, { scale: 0 });
  gsap.to(recruitCircle, {
    scale: 1,
    scrollTrigger: {
      trigger: recruitCircle,
      start: "15% center", // 要素が画面の中央に来た時に開始
      end: "+=1400", // スクロールが400px進むまでアニメーション
      pin: true, // アニメーション中は要素を固定
      pinSpacing: true, // スペースを確保して重なりを防ぐ
      scrub: true, // スクロールに同期
      onUpdate: (self) => {
        const progress = self.progress; // 進行度 (0から1)
        const startFadeProgress = 0.2; // 50%進んだ時点 (250px) から開始

        if (progress > startFadeProgress) {
          // アニメーションが50%を超えたら透明度を変化させる
          const fadeProgress =
            (progress - startFadeProgress) / (1 - startFadeProgress); // 残りの進行度に応じて透明度を変化
          const newColor = `rgba(248, 237, 226, ${fadeProgress})`; // 透明から#f8ede2へ
          document.querySelector(
            ".p-recruit-top-people"
          ).style.backgroundColor = newColor;
        }
      },
    },
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const stoker = document.getElementById("mouseStokerRecruit");
  if (!stoker) return; // ストーカー要素がない場合は何もしない

  const bg = stoker.querySelector(".c-mouseStoker-recruit__bg");
  const textElement = stoker.querySelector(".c-mouseStoker-recruit__text");

  // エリアごとの対応マッピング
  const areaMap = {
    "p-recruit-top-message": "message",
    "p-recruit-top-people__slider": "people",
    "p-recruit-top-jobs__list": "jobs",
  };

  // テキスト変更の対応マッピング
  const textMap = {
    message: "Top<br>Message",
    people: "People",
    jobs: "Jobs",
  };

  // マウスが特定エリアにあるかどうかを判定する関数
  function checkCurrentArea(mouseX, mouseY) {
    let isInArea = false;

    Object.keys(areaMap).forEach((areaClass) => {
      const areaElement = document.querySelector(`.${areaClass}`);
      if (areaElement) {
        const rect = areaElement.getBoundingClientRect();
        if (
          mouseX >= rect.left &&
          mouseX <= rect.right &&
          mouseY >= rect.top &&
          mouseY <= rect.bottom
        ) {
          const currentArea = areaMap[areaClass];
          stoker.setAttribute("data-current-area", currentArea);

          // エリアに応じたテキストを設定
          textElement.innerHTML = textMap[currentArea] || ""; // テキストを更新

          // マウスがエリア内にあることを確認
          stoker.style.opacity = "1";
          bg.style.scale = "1";
          isInArea = true;
        }
      }
    });

    // マウスがどのエリアにもない場合
    if (!isInArea) {
      stoker.setAttribute("data-current-area", "none");
      stoker.style.opacity = "0"; // ストーカーを非表示
      bg.style.scale = "0"; // 背景を縮小
    }
  }

  // マウスの移動時にエリア判定
  document.addEventListener("mousemove", function (e) {
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    // マウスストーカーの位置を更新
    const stokerHalfWidth = stoker.offsetWidth / 2;
    const stokerHalfHeight = stoker.offsetHeight / 2;
    stoker.style.left = mouseX - stokerHalfWidth + "px";
    stoker.style.top = mouseY - stokerHalfHeight + "px";

    // 現在のエリアをチェック
    checkCurrentArea(mouseX, mouseY);
  });

  // スクロール時にもエリア判定を実施
  document.addEventListener("scroll", function () {
    const mouseX = window.innerWidth / 2; // スクロール時はマウスが中心にあると仮定
    const mouseY = window.innerHeight / 2; // 同様に高さも中央に仮定
    checkCurrentArea(mouseX, mouseY);
  });
});

const items = document.querySelectorAll(
  ".js-gsap-recruit-top-jobs .p-recruit-top-jobs__item"
);

items.forEach((item, index) => {
  gsap.from(item, {
    opacity: 0,
    y: 50, // 下から上に移動
    duration: 0.8, // アニメーションの長さ
    ease: "power3.out", // イージング
    scrollTrigger: {
      trigger: item,
      start: "top 70%", // スクロール位置の開始 (アイテムが80%の位置に来たら開始)
      toggleActions: "play none none none", // アニメーションの動作
      once: true, // 一度だけアニメーションを実行
    },
    delay: index * 0.2, // 各アイテムの表示を少し遅らせる（順番に表示）
  });
});

// GSAP timeline creation
const tl = gsap.timeline({
  defaults: {
    ease: "power3.out",
    duration: 0.8, // Animation speed increased
  },
});

const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;

// Animation for .p-recruit-mv__main with clip-path, with a 0.3s delay
tl.fromTo(
  ".p-recruit-mv__main .-img",
  { clipPath: "inset(0 50% 0 50%)", duration: 0.6 },
  {
    clipPath: "inset(0 0 0 0)",
    delay: 0.3, // 0.3秒遅延
    onStart: () => {
      const mainElement = document.querySelector(".p-recruit-mv__main");
      if (mainElement) {
        mainElement.style.visibility = "visible";
      }
    },
  }
)
  .fromTo(
    ".p-recruit-mv__main .-img",
    { objectPosition: isSmallScreen ? "-100px center" : "-240px center" },
    {
      objectPosition: isSmallScreen ? "-70px center" : "-180px center",
      duration: 1.7,
      delay: 0.3,
      ease: "sine",
    },
    "-=0.5"
  )

  // Animation for .p-recruit-mv__sub with clip-path
  .fromTo(
    ".p-recruit-mv__sub .-img",
    { clipPath: "inset(0 50% 0 50%)", duration: 0.6 },
    {
      clipPath: "inset(0 0 0 0)",
      onStart: () => {
        const subElement = document.querySelector(".p-recruit-mv__sub");
        if (subElement) {
          subElement.style.visibility = "visible";
        }
      },
    },
    "-=2.0" // overlap with the previous animation
  )
  .fromTo(
    ".p-recruit-mv__sub .-img",
    { objectPosition: isSmallScreen ? "-40px center" : "-130px center" },
    {
      objectPosition: isSmallScreen ? "-10px center" : "-70px center",
      duration: 1.7,
      delay: 0.3,
      ease: "sine",
    },
    "-=2.0"
  )

  // Swipe-in animation for .-sub with faster speed
  .from(
    ".p-recruit-mv__contents .-sub",
    {
      y: "100%",
      duration: 0.6,
      onStart: () => {
        const subContentElement = document.querySelector(
          ".p-recruit-mv__contents .-sub"
        );
        if (subContentElement) {
          subContentElement.style.visibility = "visible";
        }
      },
    },
    "-=0.5" // Increased overlap for more continuity
  )

  // Swipe-in animation for .-main1 with faster speed
  .from(
    ".p-recruit-mv__contents .-main1",
    {
      y: "100%",
      duration: 0.6,
      onStart: () => {
        const main1Element = document.querySelector(
          ".p-recruit-mv__contents .-main1"
        );
        if (main1Element) {
          main1Element.style.visibility = "visible";
        }
      },
    },
    "-=0.4" // More overlap for continuity
  )

  // Swipe-in animation for .-main2 with faster speed
  .from(
    ".p-recruit-mv__contents .-main2",
    {
      y: "100%",
      duration: 0.6,
      onStart: () => {
        const main2Element = document.querySelector(
          ".p-recruit-mv__contents .-main2"
        );
        if (main2Element) {
          main2Element.style.visibility = "visible";
        }
      },
    },
    "-=0.4" // More overlap for continuity
  )

  // Swipe-in animation for .-ja with faster speed
  .from(
    ".p-recruit-mv__contents .-ja",
    {
      y: "100%",
      duration: 0.6,
      onStart: () => {
        const jaElement = document.querySelector(
          ".p-recruit-mv__contents .-ja"
        );
        if (jaElement) {
          jaElement.style.visibility = "visible";
        }
      },
    },
    "-=0.4" // More overlap for continuity
  );

gsap.from(".js-gsap-swipe-up-inview__child", {
  y: "100%", // 下からの移動距離
  duration: 1.2, // アニメーションの長さ
  ease: "power3.out",
  stagger: 0.2, // 順次遅延
  scrollTrigger: {
    trigger: ".js-gsap-swipe-up-inview",
    start: "top 80%", // トリガーポイント（画面の80%に要素が到達したとき）
    toggleActions: "play none none none", // アニメーションの開始動作
    onEnter: () => {
      document
        .querySelectorAll(".js-gsap-swipe-up-inview__child")
        .forEach((el) => {
          el.style.visibility = "visible";
        });
    },
  },
});

// 対象の全要素を取得
const paragraphs = document.querySelectorAll(".js-animation-text-wave");

paragraphs.forEach((paragraph) => {
  // テキストコンテンツを取得
  const textContent = paragraph.textContent;

  // テキストコンテンツを一文字ずつ分割して<span>タグで囲んで新しい文字列を作成
  const newTextContent = [...textContent]
    .map((char) => {
      // スペースの場合はそのまま表示するために<span>で囲む
      return char === " "
        ? `<span class="-part">&nbsp;</span>`
        : `<span class="-part">${char}</span>`;
    })
    .join("");

  // 新しい文字列をHTMLに挿入
  paragraph.innerHTML = newTextContent;

  // GSAPアニメーション
  gsap.fromTo(
    paragraph.querySelectorAll("span"), // アニメーションさせる<span>要素
    {
      y: "100%", // アニメーション開始時の状態（CSSと一致）
    },
    {
      y: 0, // アニメーション終了時の状態
      stagger: 0.05,
      scrollTrigger: {
        trigger: paragraph, // アニメーションを開始する要素（各段落ごと）
        start: "top 80%", // ビューポートの上部から80%の位置に来たら開始
        ease: "power2.out",
        toggleActions: "play none none none", // アニメーションは再生のみ
        once: true, // 一度実行したら再度実行しない
        onEnter: () => {
          // アニメーションが準備された段階で表示に切り替える
          paragraph.style.visibility = "visible";
        },
      },
    }
  );
});

const mwformPrivacyElement = document.querySelector(
  ".js-mwform-privacy .mwform-checkbox-field-text"
);

if (mwformPrivacyElement) {
  // テキストの設定
  mwformPrivacyElement.textContent = "個人情報の取り扱いについて";

  // リンク要素を作成
  const link = document.createElement("a");
  link.href = "/privacy-policy";
  link.target = "_blank";
  link.rel = "noreferrer";
  link.classList.add("u-underline");
  link.textContent = "プライバシーポリシー";

  // 同意文を続けて追加
  const consentText = document.createTextNode("に同意します。");

  // リンクとテキストを要素に追加
  mwformPrivacyElement.appendChild(link);
  mwformPrivacyElement.appendChild(consentText);
}
